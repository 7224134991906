// src/components/AdminPage.js
import React from 'react';
import Favorites from "./Favorites/Favorites";

function AdminPage() {

  return (
    <div className="admin-page">
     <Favorites></Favorites>
    </div>
  );
}

export default AdminPage;

import React, { useState, useEffect } from 'react';
import './Favorites.css';

const Favorites = () => {
  const allNames = [
    'László', 'Márton', 'Dániel', 'Zoltán', 'Ferenc',
    'Attila', 'István', 'János', 'Gábor', 'Tamás',
    'Péter', 'Balázs', 'András', 'György', 'Imre',
    'Csaba', 'Béla', 'Szilveszter', 'Károly', 'Sándor'
  ].sort();

  const [searchTerm, setSearchTerm] = useState('');
  const [currentPage, setCurrentPage] = useState(0);
  const [grid, setGrid] = useState(Array(8).fill(null));
  const [filteredNames, setFilteredNames] = useState(allNames);

  const itemsPerPage = 5;

  useEffect(() => {
    const newFilteredNames = allNames.filter(name =>
      name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Only update state if the filtered names have changed
    if (JSON.stringify(newFilteredNames) !== JSON.stringify(filteredNames)) {
      setFilteredNames(newFilteredNames);
      setCurrentPage(0); // Reset to the first page when the search term changes
    }
  }, [searchTerm, allNames, filteredNames]);

  const paginatedNames = filteredNames.slice(
    currentPage * itemsPerPage,
    (currentPage + 1) * itemsPerPage
  );

  const addToGrid = (name) => {
    const newGrid = [...grid];
    newGrid[grid.indexOf(null)] = name;
    setGrid(newGrid);
  };

  const removeFromGrid = (index) => {
    const newGrid = [...grid];
    newGrid[index] = null;
    setGrid(newGrid);
  };

  const handleNextPage = () => {
    if ((currentPage + 1) * itemsPerPage < filteredNames.length) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 0) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleDragStart = (e, name) => {
    e.dataTransfer.setData('drag-type', 'contact-item');
    e.dataTransfer.setData('text/plain', name);
  };

  const handleGridDragStart = (e, index) => {
    e.dataTransfer.setData('drag-type', 'grid-item');
    e.dataTransfer.setData('grid-index', index);
  };

  const handleDrop = (e, index) => {
    e.preventDefault();
    const dragType = e.dataTransfer.getData('drag-type');

    if (dragType === 'contact-item') {
      const name = e.dataTransfer.getData('text/plain');
      if (name) {
        const newGrid = [...grid];
        newGrid[index] = name;
        setGrid(newGrid);
      }
    } else if (dragType === 'grid-item') {
      handleGridDrop(e, index);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const handleGridDrop = (e, index) => {
    e.preventDefault();
    const fromIndex = e.dataTransfer.getData('grid-index');
    if (fromIndex !== null && fromIndex !== '') {
      const newGrid = [...grid];
      const fromIndexNum = parseInt(fromIndex, 10);
      const temp = newGrid[index];
      newGrid[index] = newGrid[fromIndexNum];
      newGrid[fromIndexNum] = temp;
      setGrid(newGrid);
    }
  };

  return (
    <div className="favorites-container">
      <div className="phone-grid-container">
        <div className="phone-frame">
          {grid.map((name, index) => (
            <div
              key={index}
              className="grid-item"
              onDrop={(e) => handleDrop(e, index)}
              onDragOver={handleDragOver}
              draggable
              onDragStart={(e) => handleGridDragStart(e, index)}
            >
              {name && (
                <>
                  <span>{name}</span>
                  <button className="delete-button" onClick={() => removeFromGrid(index)}>x</button>
                </>
              )}
            </div>
          ))}
        </div>
      </div>
      <div className="contact-list-container">
        <input
          type="text"
          placeholder="Search contact"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <div className="contact-list">
          {paginatedNames.map((name, index) => (
            <div
              key={index}
              className="contact-item"
              draggable
              onDragStart={(e) => handleDragStart(e, name)}
              onClick={() => addToGrid(name)}
            >
              <span className="contact-icon">📞</span> {name}
            </div>
          ))}
        </div>
        <div className="pagination-buttons">
          {currentPage > 0 && (
            <button onClick={handlePreviousPage} className="previous-button">Previous</button>
          )}
          <button onClick={handleNextPage} className="next-button">Next</button>
        </div>
      </div>
    </div>
  );
};

export default Favorites;

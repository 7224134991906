import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { auth } from '../firebase';
import { signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import axios from 'axios';

function SignIn() {
  const navigate = useNavigate();
  const params = useParams();

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      const token = await user.getIdToken();
      const ptoken = params.ptoken;


      // Persist the user to the backend after sign-in
      await axios.post(
        `${process.env.REACT_APP_BACKEND_URL}/admin`,
        { pairingToken: ptoken },
        { headers: { Authorization: `Bearer ${token}` } }
      );

      // Redirect to admin page after successful sign-in
      navigate('/admin');
    } catch (error) {
      console.error('Error during Google Sign-In:', error);
    }
  };

  return (
    <div>
      <h2>Sign In</h2>
      <button onClick={handleGoogleSignIn}>Sign in with Google</button>
    </div>
  );
}

export default SignIn;

import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { auth } from './firebase';
import SignIn from './components/SignIn';
import AdminPage from './components/AdminPage';

function App() {
  const [user, loading] = useAuthState(auth);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route
          path="/signin/:ptoken?"
          element={user ? <Navigate to="/admin" replace /> : <SignIn />}
        />
        <Route
          path="/admin"
          element={
            user
              ? <AdminPage />
              : <Navigate to="/signin" replace />
          }
        />
        {/* Redirect unknown routes to Sign In */}
        <Route path="*" element={<Navigate to="/signin" replace />} />
      </Routes>
    </Router>
  );
}

export default App;
